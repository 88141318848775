import { Card, Color, Metric } from "@tremor/react"
import { useEffect, useState } from "preact/compat"
import { api_fetch } from "../../api/client.tsx"
import { safeExec } from "../../utils/util.tsx"
import { currencyFormatter, Data, TitleArea } from "../common.tsx"

export type NumberCardData = {
  title: string
  color: Color
  query?: string
  query_name?: string
  return_aggregates?: boolean
  titleTooltip?: string
  pathFragment?: string
  linkFragment?: (id: number) => string
  clientFilter?: string
  valueFormatter?: (value: number) => string
  defaultSortColName?: string
}

export function LoadingSpinner({ color }: { color: string }) {
  let cls = `w-8 h-8 border-t-4 border-${color}-500 border-solid rounded-full animate-spin`
  return <div class={cls} />
}

export async function make_query_request(
  table: NumberCardData,
  return_aggregates: boolean,
) {
  let res
  if (table.query_name) {
    res = await api_fetch<Data<number[]>>(`/query/run/static`, {
      body: {
        query_name: table.query_name,
        return_aggregates: table.return_aggregates !== undefined
          ? table.return_aggregates
          : return_aggregates,
      },
    })
  } else if (table.query) {
    res = await api_fetch<Data<number[]>>(`/query/run`, {
      body: {
        text: table.query,
      },
    })
  } else {
    throw new Error("No query or query_name provided")
  }
  return res
}

export function NumberCard(card: NumberCardData) {
  const [value, setValue] = useState<number | null>(null)
  const [sumValue, setSumValue] = useState<number | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(
    () =>
      safeExec(async () => {
        let res = await make_query_request(
          card,
          true,
        )
        if (!res.ok) {
          setLoading(false)
          throw new Error("Failed to fetch data")
        }
        setLoading(false)

        setValue(res.value.data.rows[0][0])
        setSumValue(res.value.data.rows[0][1])
      }),
    [],
  )

  return (
    <Card
      className="h-full"
      decoration="bottom"
      decorationColor={card.color === "red" ? "slate" : card.color}
    >
      <div class="flex mb-4">
        <TitleArea
          title={card.title}
          link={card.pathFragment}
          titleTooltip={card.titleTooltip}
        />
      </div>
      <Metric>
        <p className={card.color ? `text-${card.color}-500` : ""}>
          {loading
            ? (
              <LoadingSpinner color={card.color} />
            )
            : value === null
            ? (
              ""
            )
            : card.valueFormatter
            ? (
              card.valueFormatter(value)
            )
            : value}
        </p>
      </Metric>
      {sumValue && (
        <p className={card.color ? `text-${card.color}-500` : ""}>
          {loading
            ? (
              <LoadingSpinner color={card.color} />
            )
            : sumValue === null
            ? (
              ""
            )
            : (
              currencyFormatter(sumValue)
            )}
        </p>
      )}
    </Card>
  )
}
