import "./css/index.css"
import { hydrate } from "preact"
import { Routes } from "./app/route.tsx"

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string | undefined

import * as Sentry from "@sentry/react"
import { Toaster } from "./components/ui/toaster.tsx"

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 1.0,
    // replaysOnErrorSampleRate: 1.0,
  })
}

hydrate(<><Routes /><Toaster /></>, document.getElementById("app") as HTMLElement)
