import { useState } from "preact/compat"
import { Integration } from "src/api/integration.tsx"
import { Ok, Result } from "src/utils/type.tsx"
import { useLocation } from "wouter-preact"
import { api_fetch, HttpError } from "../api/client.tsx"
import { Form } from "../component/form/Form.tsx"
import { useFormState } from "../component/form/form_state.tsx"
import { Input, PasswordInput } from "../component/form/Input.tsx"
import { LoginScreen } from "../component/LoginScreen.tsx"
import { Button } from "@/components/ui/button.tsx"
import gmailLogo from "/img/gmail-logo.png"

async function create_raw_email_integration(
  username: string,
  password: string
): Promise<Result<Integration, HttpError>> {
  let options = {
    body: {
      service: "raw_email",
      credential: {
        username: username,
        password: password,
      },
    },
  }
  const res = await api_fetch<Integration>("/integration", options)
  if (!res.ok) {
    console.error(res.error)
    return res
  }
  return Ok(res.value.data)
}

export function RawEmailIntegration() {
  const [, setLocation] = useLocation()
  const [loading, setLoading] = useState(false)

  const form = useFormState({
    username: "",
    password: "",
  })

  async function onSubmit() {
    setLoading(true)
    form.set_form_errors([])
    const res = await create_raw_email_integration(form.username.value, form.password.value)
    setLoading(false)
    if (!res.ok) {
      form.set_form_errors([res.error.message.replace("Anyhow error: ", "")])
      return
    }

    form.set_form_success("Email connected successfully. Connect another email if we will need to pull data from another email account.")
    form.set_form_data({ username: "", password: "" })

    setTimeout(() => {
      form.set_form_success("")
    }, 4000)
  }

  return (
    <LoginScreen title="">
      <div>
        <Form class="space-y-6" onSubmit={onSubmit} alerts={form.form_alerts}>
          <div className="flex justify-center">
            <img src={gmailLogo} alt="Gmail Logo" class="h-6" />
          </div>
          <p className="mt-6">
            Enter your email account information so we can sync your UNFI data for you.
          </p>
          <Input
            label="Username"
            type="text"
            name="username"
            required={true}
            bind={form.username}
          />
          <PasswordInput label="Password" required={false} bind={form.password} />
          <Button
            type="submit"
            // className="w-full btn text-white bg-kehe-500 rounded-md hover:bg-kehe-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-plue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
            className="w-full btn btn-primary py-2"
            disabled={loading}>
            Connect Email
          </Button>
          <Button
            className="w-full btn bg-white text-black border flex p-2 hover:bg-slate-300"
            onClick={() => setLocation("/deductions")}>
            Finished Adding Emails
          </Button>
        </Form>
      </div>
    </LoginScreen>
  )
}
