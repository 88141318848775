import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"

import { CircleCheckIcon, Dot, File, NotebookPen, ShieldAlert } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { classes } from "../../../utils/util"
import { useState } from "preact/hooks"
import { DISPUTE_TYPE_DESCRIPTIONS } from "../index"
import { StatusFlag } from "../status_state"
import { api_fetch } from "src/api/client.tsx"
import { formatDistanceToNow } from "date-fns"

function returnFileSize(number) {
  if (number < 1e3) {
    return `${number} bytes`
  } else if (number >= 1e3 && number < 1e6) {
    return `${(number / 1e3).toFixed(1)} KB`
  } else {
    return `${(number / 1e6).toFixed(1)} MB`
  }
}

interface NotesDialogContentProps {
  row: any // Adjust the type according to your data structure
  note: string
  setNote: (note: string) => void
}

export const NoteContent = ({ note }) => {
  return (
    <div class="flex flex-col mb-2">
      <div class="mt-2 mb-1 rounded-tl-lg rounded-tr-lg rounded-br-lg bg-plue-100 p-2 text-sm text-plue-700">
        {note?.message}
      </div>
      <div class="flex items-center text-sm">
        <span class="text-gray-900">{note?.actor_name}</span>
        <Dot class="h-2 w-2 mx-1" />
        <span>{formatDistanceToNow(new Date(note?.updated_at), { addSuffix: true })}</span>
      </div>
    </div>
  )
}

const NotesDialogContent = ({ row, note, setNote }: NotesDialogContentProps) => {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle className="mb-2">Notes</DialogTitle>
        <DialogDescription className="overflow-y-auto max-h-96">
          {row.original.notes.length > 0
            ? row.original.notes.map(note => <NoteContent note={note} />)
            : null}
        </DialogDescription>
      </DialogHeader>
      <Textarea
        value={note}
        onChange={e => setNote(e.target.value)}
        onKeyDown={e => {
          if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
            e.preventDefault()
            handleSaveNote({deduction_id: row.original.id, note, setNote})
          }
        }}
      />
      <Button onClick={() => handleSaveNote({deduction_id: row.original.id, note, setNote})}>Save</Button>
    </DialogContent>
  )
}

export const handleSaveNote = async ({deduction_id, note, setNote}: {deduction_id: string, note: string, setNote: any}) => {
  const res = await api_fetch(`/deductions/note`, {
    method: "POST",
    body: {
      deduction_id: deduction_id,
      note,
    },
  })

  if (res.ok) {
    setNote("")
    StatusFlag.set(flag => !flag) // hack for now to force a re-render
  } else {
    console.error("Failed to save note")
  }
}

const FileDisputeDrawer = ({ row }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [uploading, setUploading] = useState(false)
  const [disputeMessage, setDisputeMessage] = useState(
    "Hi, please see attached documents showing that this is an invalid charge. Thank you in advance and please let me know if you have any questions."
  )
  const [note, setNote] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false) // State to manage dialog open state

  function handleFileChange(event) {
    const preview = document.querySelector(".preview")
    while (preview.firstChild) {
      preview.removeChild(preview.firstChild)
    }

    const updatedFiles = [...selectedFiles, ...event.target.files]
    setSelectedFiles(updatedFiles)
    if (updatedFiles.length !== 0) {
      const list = document.createElement("ol")
      preview.appendChild(list)

      for (const file of updatedFiles) {
        const listItem = document.createElement("li")
        const para = document.createElement("p")
        para.textContent = `${file.name}, ${returnFileSize(file.size)}`

        listItem.appendChild(para)
        list.appendChild(listItem)
      }
    }
  }

  const handleMarkValidated = async () => {
    const res = await api_fetch(`deductions/status`, {
      method: "POST",
      body: {
        deduction_id: row.original.id,
        status: "validated",
      },
    })

    if (res.ok) {
      console.log("Status updated successfully")
    } else {
      console.error("Failed to update status")
    }

    StatusFlag.set(flag => !flag)
  }

  const handleFileDispute = async () => {
    setUploading(true)

    let formData = new FormData()

    for (const file of selectedFiles) {
      formData.append("files", file)
    }

    formData.append("deduction_id", row.original.id)
    // hardcoded for now: Jenna used to recommend to use the same description for all disputes
    // so that the same rep receives all disputes and you build a relationship with them
    formData.append("dispute_type_description", DISPUTE_TYPE_DESCRIPTIONS.chargeback_deduction)
    formData.append("message", disputeMessage)

    const res = await fetch("/api/dispute", {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    })

    if (res.ok) {
      console.log("Dispute filed successfully")
    } else {
      console.error("Failed to file dispute")
    }

    StatusFlag.set(flag => !flag)
    setUploading(false)
  }

  return (
    <Drawer>
      <div class="flex justify-between items-center">
        {row.original.status_value !== "validated" ? (
          <CircleCheckIcon
            className="h-5 w-5 cursor-pointer text-gray-400"
            onClick={handleMarkValidated}
          />
        ) : (
          <CircleCheckIcon className="h-5 w-5 text-green-600" />
        )}

        <DrawerTrigger>
          <ShieldAlert className="h-5 w-5 cursor-pointer text-red-600" />
        </DrawerTrigger>

        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger>
            {row.original.notes.length > 0 ? (
              <NotebookPen className="h-5 w-5 cursor-pointer text-gray-900" />
            ) : (
              <NotebookPen className="h-5 w-5 cursor-pointer text-gray-400" />
            )}
          </DialogTrigger>

          {isDialogOpen && (
            <NotesDialogContent
              row={row}
              note={note}
              setNote={setNote}
            />
          )}
        </Dialog>
      </div>

      <DrawerContent >
        <DrawerHeader>
          <DrawerTitle>File dispute</DrawerTitle>
          <DrawerDescription>
            Review, edit, and upload relevant files then hit submit to file dispute.
          </DrawerDescription>
          <DrawerTitle className="text-md pt-2">Title</DrawerTitle>
          <DrawerDescription>
            {`Supplier Dispute Form - Invoice #: ${row.original.invoice_number}`}
          </DrawerDescription>
          <DrawerTitle className="text-md pt-2">Message</DrawerTitle>
          <Textarea value={disputeMessage} onChange={e => setDisputeMessage(e.target.value)} />
          <DrawerTitle className="text-md pt-2">Attachments</DrawerTitle>
          <div className="flex flex-col">
            {row.original.s3_url && (
              <div className="flex items-center">
                <File className="w-4 h-4 mr-1" />
                <DrawerDescription>Original backup</DrawerDescription>
              </div>
            )}

            <form method="post" enctype="multipart/form-data">
              <div class={classes(row.original.s3_url ? "mt-4" : "", "mb-2")}>
                <label for="image_uploads" class="btn btn-secondary">
                  Select files
                </label>
                <input
                  type="file"
                  style="opacity: 0"
                  id="image_uploads"
                  name="image_uploads"
                  multiple
                  onChange={handleFileChange}
                  className="mt-2"
                />
              </div>
              <div class="preview" />
            </form>
          </div>
        </DrawerHeader>
        <DrawerFooter className="flex flex-row items-start">
          <DrawerClose>
            <Button onClick={handleFileDispute} disabled={uploading}>
              {uploading ? "Uploading..." : "Submit"}
            </Button>
          </DrawerClose>
          <DrawerClose>
            <Button variant="outline">Cancel</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default FileDisputeDrawer
