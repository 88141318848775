import { useState } from "preact/compat"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { classes } from "src/utils/util"

interface GenericFilterProps {
  items: Array<number | string>
  selectedItem: any
  handleOnChange: (item: any) => void
  itemToString: (item: any) => string
  className?: string
}

export function GenericFilter({
  items,
  selectedItem,
  handleOnChange,
  itemToString,
  className,
}: GenericFilterProps) {

  return (
    <div className={className}>
      <Select onValueChange={handleOnChange}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder={itemToString(selectedItem)} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {items.map((item, idx) => (
              <SelectItem key={idx} value={item}>
                <div className="flex items-center">
                  <span className={classes("ml-3 truncate")}>{itemToString(item)}</span>
                </div>
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  )
}
