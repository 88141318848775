export function downloadCsv(csvData, filename) {
  const blob = new Blob([csvData], { type: "text/csv" })
  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url

  // Format the download filename with the current date
  const today = new Date()
  const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(today.getDate()).padStart(2, "0")}`
  a.download = `${filename}_${formattedDate}.csv`

  // Trigger the download and clean up the URL object
  a.click()
  URL.revokeObjectURL(url)
}

export function filterOutIdColumns(data) {
  const filteredData = []

  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    const filteredItem = {}

    for (const key in item) {
      let INTERNAL_KEYS = ["id", "created_at", "updated_at", "raw_data", "backup_s3_uri"]
      if (
        Object.prototype.hasOwnProperty.call(item, key) &&
        !key.endsWith("_id") && INTERNAL_KEYS.indexOf(key) === -1
      ) {
        filteredItem[key] = item[key]
      }
    }

    filteredData.push(filteredItem)
  }

  return filteredData
}

export function convertToCsv(data) {
  if (!data || !data.length) {
    return ""
  }

  const headers = Object.keys(data[0])
  const headerRow = headers.join(",")

  const dataRows = data
    .map(item => {
      return headers
        .map(header => {
          const cellValue = item[header]
          return `"${cellValue != null ? cellValue : ""}"`
        })
        .join(",")
    })
    .join("\n")

  return [headerRow, dataRows].join("\n")
}
