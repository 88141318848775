import { Backup, DeductionResponse, EmailMessage, TaskStatus } from "@/api/integration"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { formatDistributor } from "@/utils/util"
import { capitalCase } from "change-case"
import { format } from "date-fns"
import { Calendar, Check, CircleCheck, FileCog, FileUp, FileWarning, MailPlus, SendHorizonal } from "lucide-react"
import { api_fetch } from 'src/api/client.tsx';
import { StatusFlag } from "../status_state"

interface Update {
  date: string | Date
  title: string
  actor: string
  description?: string
  action?: any
}

const UPDATE_ICONS = {
  "Deduction created": <FileUp className="text-gray-400 ml-4" />,
  "Dispute created": <FileWarning className="text-gray-400 ml-4" />,
  "Backup parsed": <FileCog className="text-gray-400 ml-4" />,
  "Email sent": <SendHorizonal className="text-gray-400 ml-4" />,
  "Email received": <MailPlus className="text-gray-400 ml-4" />,
  "pending task": <CircleCheck className="text-gray-400 ml-4" />,
  "completed task": <Check className="text-plue-400 ml-4" />,
}

const getUpdateIcon = (title: string) => {
  for (const key in UPDATE_ICONS) {
    if (title.toLowerCase().startsWith(key.toLowerCase())) {
      return UPDATE_ICONS[key]
    }
  }
  return null; 
}

const ACTOR_ICONS = {
  Promoted: <img class="block h-6 w-6 mr-2" src="/img/promoted.png" alt="Promoted" />,
  unfi: <img class="block w-10 mr-2" src="/img/unfi.webp" alt="UNFI" />,
}

const parseEmailDomain = (email: string) => {
  const [_, domain] = email.split("@")
  return domain?.split(".")[0]
}


  const formatTaskType = (taskType: string) => {
    const taskTypeMapping: { [key: string]: string } = {
      'file-dispute': "file dispute",
      'pull-backup': "pull backup",
      'followup': "follow-up",
      'validate': "validate deduction",
      'map-accounting-code': "map accounting code",
    };
  
    return taskTypeMapping[taskType] || taskType;
}

const handleTaskComplete = async (task_id: string | undefined) => {
  const res = await api_fetch(`/task`, {
    method: 'PUT',
    body: {
      status: TaskStatus.Completed,
      task_id 
    }
  })
  if (!res.ok) {
    console.error("Failed to mark task complete")
  }
  // todo: toast
  StatusFlag.set(flag => !flag)
}

const getTaskUpdate = (deduction: DeductionResponse): Update => {
  let task = deduction.task
  return {
    date: task?.task_created_at ?? "",
    title: `${capitalCase(task?.task_status ?? "")} task`,
    description: `${task?.task_user_email?.split("@")[0] ?? ""} assigned to ${formatTaskType(task?.task_type ?? "")}`,
    actor: task?.task_assigner_email?.split("@")[0] ?? "",
    action: task?.task_status?.toLowerCase() === TaskStatus.Pending ? <Button onClick={() => handleTaskComplete(task?.task_id)} variant="secondary">Mark complete</Button> : ''
  }
}


export function Updates({ deduction, backup, emails }: { deduction: DeductionResponse; backup?: Backup; emails: EmailMessage[] }) {
  const updates: Update[] = [
    {
      date: deduction.created_at,
      title: "Deduction created",
      description: `Synced from ${formatDistributor(deduction.source)}`,
      actor: "Promoted",
    },
  ]
  if (backup) {
    updates.unshift({
      date: backup.created_at,
      title: "Backup parsed",
      description: "See details in table below",
      // todo: add link to the file here also for convenience?
      actor: "Promoted",
    })
  }
  emails?.forEach(email => {
    if (email.attachments) {
      const isFirstEmail = email.sequence === 0
      // email.attachments.some(attachment => attachment.s3_uri.includes("dispute")) 
      updates.unshift({
        date: email.date,
        title: isFirstEmail ? "Email sent" : "Email received",
        description: isFirstEmail ? "Dispute filed" : "Response received",
        actor: parseEmailDomain(email.from),
      })
    }
  })
  if (deduction.task?.task_created_at) {
    updates.unshift(getTaskUpdate(deduction))
  }

  return (
    <Card className="h-96 overflow-y-auto">
      <div class="flex justify-between items-center m-4">
        <h2 class="flex text-xl">
          <Calendar class="h-6 w-6" />
          <span class="ml-2">Updates</span>
        </h2>
      </div>
      <div class="px-4 w-full flex justify-center">
        <Separator class="w-1/2" />
      </div>
      <div class="flex flex-col gap-4 mt-4 mb-4">
        {updates.map((update, i) => (
          <>
            <div class="flex gap-2 items-start ">
              <div class="flex items-center justify-center">{getUpdateIcon(update.title)}</div>
              <div class="flex flex-col flex-grow">
                <div class="text-lg ">{update.title}</div>
                <span class="text-gray-500">{update.description}</span>
                <div class="flex justify-between w-full mt-3">
                  <div class="flex items-center">
                    {update.action || ACTOR_ICONS[update.actor]}
                  </div>{" "}

                  <div class="flex items-center">
                    <Calendar className="text-gray-500 h-4 w-4 " />

                    <div class="text-sm text-gray-500 text-right ml-2 mr-4">{format(update.date, 'PPP')}</div>
                  </div>{" "}

                </div>
              </div>
            </div>
            {i < updates.length - 1 && <hr class="border-t border-gray-200 ml-12 mx-4" />}
          </>
        ))}
      </div>
    </Card>
  )
}
