import {useEffect, useState} from "preact/compat"
import {Link} from "wouter-preact"
import {formatDistributor, useAsyncEffect} from "@/utils/util.tsx"
import {Backup, DeductionResponse, DisputeMessage, EmailMessage} from "@/api/integration.tsx"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import {Uuid} from "src/utils/type.tsx"
import {Card} from "@/components/ui/card.tsx"
import {Separator} from "@/components/ui/separator.tsx"
import {Updates} from "./updates.tsx"
import {Conversations} from "./conversations.tsx"
import {LoadingSpinner} from "../summary_cards.tsx"
import {Badge, BadgeVariantOptionType} from "@/components/ui/badge.tsx"
import {capitalCase} from "change-case"
import {statusIcons} from "../table/columns.tsx"
import {DeductionFile, Files} from "./files.tsx"
import {StatusFlag} from "../status_state.tsx"
import {BackupTable} from "./backup_table.tsx"
import {backup_columns} from "./backup_columns.tsx"
import { api_fetch } from 'src/api/client.tsx';

function getFiles(deduction: DeductionResponse, emails: EmailMessage[], messages: DisputeMessage[]): DeductionFile[] {
  const files: DeductionFile[] = []
  for (const email of emails) {
    for (const attachment of email.attachments) {
      if (attachment.s3_uri == deduction.backup_s3_uri || attachment.s3_uri == deduction.check_s3_uri) {
        continue
      }
      files.push({
        display_name: attachment.s3_uri.split('/').pop() ?? "Email Attachment",
        date: email.date,
        s3_uri: attachment.s3_uri,
        deduction_id: deduction.id,
      })
    }
  }
  for (const message of messages) {
    for (const s3_uri of message.attachment_s3_paths ?? []) {
      if (message.sequence > 0) {
      files.push({
        display_name: `Freshdesk Backup`,
        date: message.sent_at,
        s3_uri,
        deduction_id: deduction.id,
      })
    }
    }
  }
  if (deduction.backup_s3_uri) {
    files.push({
      display_name: `${capitalCase(deduction.source)} Backup`,
      // TODO should be date of the backup request.
      date: deduction.created_at,
      s3_uri: deduction.backup_s3_uri,
      deduction_id: deduction.id,
    })
  }
  if (deduction.check_s3_uri) {
    files.push({
      display_name: `${capitalCase(deduction.source)} Check`,
      date: deduction.check_date,
      s3_uri: deduction.check_s3_uri,
      deduction_id: deduction.id,
    })
  }
  return files.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
}

export function DeductionDetail({params}: { params: { deduction_id: Uuid } }) {
  const [loading, setLoading] = useState(true)
  const [backupLoading, setBackupLoading] = useState(true)
  const [deduction, setDeduction] = useState<DeductionResponse>()
  const [backup, setBackup] = useState<Backup[]>([])
  const [emails, setEmails] = useState<EmailMessage[]>([])
  const [emailsLoading, setEmailsLoading] = useState(true)
  const [messages, setMessages] = useState<DisputeMessage[]>([])
  const [messagesLoading, setMessagesLoading] = useState(true)
  const [files, setFiles] = useState<DeductionFile[]>([])
  // todo: query for deduction and backup separately?? probably ok to just get those pieces of data at once
  const statusFlag = StatusFlag.use(sf => sf!)

  useAsyncEffect(async () => {
    const deductionRes = await api_fetch<DeductionResponse>(`/deduction/${params.deduction_id}`)
    const backupRes = await api_fetch<Backup[]>(`/deduction/${params.deduction_id}/backup`)
    const emailRes = await api_fetch<EmailMessage[]>(`/deduction/${params.deduction_id}/emails`)
    const messagesRes = await api_fetch<DisputeMessage[]>(`/deduction/${params.deduction_id}/messages`)

    if (!deductionRes.ok) {
      setLoading(false)
      throw new Error("Failed to fetch deduction")
    }

    setDeduction(deductionRes.value.data)
    if (backupRes.ok) {
      setBackup(backupRes.value.data)
    }
    setBackupLoading(false)

    if (emailRes.ok) {
      setEmails(emailRes.value.data)
    }

    if (messagesRes.ok) {
      setMessages(messagesRes.value.data)
    }
    setEmailsLoading(false)
    setMessagesLoading(false)
    setLoading(false)

  }, [statusFlag])

  if (!deduction || loading || emailsLoading || messagesLoading) {
    return <LoadingSpinner color='plue-500'/>
  }

  useEffect(() => {
    setFiles(getFiles(deduction, emails, messages))
  }, [emails, deduction, messages])

  return (
    <div>
      <NavContext invoiceNumber={deduction.invoice_number}/>
      <Header title={`Deduction Details`}/>
      <DeductionCard deduction={deduction}/>
      {/* TODO: stack the updates and files on top of each other. make convos wider */}
      <div class="columns-2 gap-4 mt-4">
        <div class="flex flex-col gap-4">
          <Updates deduction={deduction} backup={backup[0]} emails={emails}/>
          <Files files={files}/>
        </div>
        <Conversations deduction={deduction} emails={emails} messages={messages}/>
      </div>
      <div class="w-full min-h-44">
        <BackupTable loading={backupLoading} columns={backup_columns} data={backup}/>
      </div>
    </div>
  )
}

const Header = ({title}: {title: string}) => (
    <div className="-mx-6 flex flex-col sm:gap-4 py-2 max-w-fit">
      <header className="sticky top-0 z-30 flex h-4 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
        <h1 class="text-2xl">{title}</h1>
      </header>
    </div>
)

function DeductionCard({deduction}: { deduction: DeductionResponse }) {
  const KEYS_TO_DISPLAY = [
    {key: 'invoice_number', displayKey: 'Invoice #'},
    {key: 'invoice_date', displayKey: 'Invoice Date'},
    {key: 'po_number', displayKey: 'PO #'},
    {key: 'invoice_amount', displayKey: 'Invoice Amount'},
    {key: 'category', displayKey: 'Category'},
    {key: 'description', displayKey: 'Distributor Description'},
    {key: 'retailer_name', displayKey: 'Retailer'},
    {key: 'source', displayKey: 'Distributor', customRender: (value: string) => formatDistributor(value)},
    {
      key: 'status_value', displayKey: 'Status', customRender: (value: string | undefined) => {
        const iconKey = value?.toLowerCase() as keyof typeof statusIcons || 'new';
        const Icon = statusIcons[iconKey];
        return value ? (
          <Badge className="pl-1 rounded-sm text-center" variant={value.toLowerCase() as BadgeVariantOptionType}>
            {Icon}
            {capitalCase(value)}
          </Badge>
        ) : (
          "-"
        )
      }
    },

    // todo: notes, backup deduction (product, promo type / dates, win confidence)
  ];

  return (
    <Card className="overflow-x-auto py-2 bg-white mt-6">
      <div className="flex justify-between items-center">
        {KEYS_TO_DISPLAY.map(({key, displayKey, customRender}, index) => (
          <>
            <div className="flex flex-col items-center justify-center mx-4">
              <div className="text-sm text-gray-500">{displayKey}</div>
              <div className="text-sm mt-2">
                {customRender ? customRender(deduction[key as keyof DeductionResponse] as string ?? "") : (deduction[key as keyof DeductionResponse] ?? "-")}
              </div>
            </div>
            {index < KEYS_TO_DISPLAY.length - 1 && ( // Only add separators between items, not after the last one
              <Separator orientation="vertical" className="h-full "/>
            )}
          </>
        ))}
      </div>
    </Card>
  );
};

function NavContext({invoiceNumber}: { invoiceNumber: string }) {
  return (
    <div className="-mx-6 flex flex-col sm:gap-4 py-2 pb-4 max-w-fit">
      <header
        className="sticky top-0 z-30 flex h-4 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
        <Breadcrumb className="hidden md:flex">
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link href="/deductions">Deductions</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator/>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link href="#">{invoiceNumber}</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </header>
    </div>
  )
}
  