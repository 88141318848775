"use client"

import {
  ColumnDef,
  FilterFn,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { useEffect, useMemo, useState } from "preact/compat"
import { Button } from "@/components/ui/button"

import { DebouncedInput } from "./debounced_input"
import { Skeleton } from "@/components/ui/skeleton"
import { PageControls } from "./page_controls"
import { api_fetch } from "src/api/client.tsx"
import {
  Check,
  ChevronDown,
  CircleCheck,
  Columns2,
  Download,
  File,
  FileWarning,
  X,
} from "lucide-react"
import { classes, updateSearchParams, useAsyncEffect, useSearchParams } from "src/utils/util.tsx"
import { Separator } from "@/components/ui/separator"
import { DISPUTE_TYPE_DESCRIPTIONS } from ".."
import { StatusFlag } from "../status_state"
import { convertToCsv, downloadCsv, filterOutIdColumns } from "./util"
import { useLocation } from "wouter-preact"
import { AssignmentForm } from "./assignment_form"
import { UserState } from 'src/auth/user.tsx';

interface DataTableProps<TData, TValue> {
  loading: boolean
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  if (value.toLowerCase() === "misc") {
    if (row.getValue("description") === "" && row.getValue("category") === "") {
      return true
    }
  }
  if (value.toLowerCase() === "new") {
    if (row.getValue("status_value") === "") {
      return true
    }
  }

  const itemValue = row.getValue(columnId)
  if (!itemValue) return false

  const itemRank = rankItem(itemValue, value)
  addMeta({ itemRank })

  return itemRank.passed
}

export function DataTable<TData, TValue>({
  loading,
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const params = useSearchParams<{ search?: string; days?: number; distributor?: string }>()
  const [, setLocation] = useLocation()
  const user = UserState.use()

  const [rowSelection, setRowSelection] = useState({})
  const [sorting, setSorting] = useState<SortingState>([{ id: "invoice_date", desc: true }])
  const [globalFilter, setGlobalFilter] = useState(params.search ?? "")
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    check_amount: false,
  })
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })
  const [requestBackupDisabled, setRequestBackupDisabled] = useState(false)
  const [assignmentPopoverOpen, setAssignmentPopoverOpen] = useState(false)
  const [users, setUsers] = useState([])

  const handleChangeGlobalFilter = (value: string) => {
    let qs = updateSearchParams(params, [{ key: "search", value }])
    setLocation(`/deductions?${qs.toString()}`, { replace: true })
    setGlobalFilter(value)
  }

  const table = useMemo(() => {
    return useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      globalFilterFn: fuzzyFilter,
      onColumnVisibilityChange: setColumnVisibility,
      onGlobalFilterChange: handleChangeGlobalFilter,
      onRowSelectionChange: setRowSelection,
      onSortingChange: setSorting,
      onPaginationChange: setPagination,
      state: {
        columnVisibility,
        rowSelection,
        sorting,
        globalFilter,
        pagination,
      },
    })
  }, [data, columns, columnVisibility, globalFilter, rowSelection, sorting, pagination])

  useAsyncEffect(async () => {
    let res = await api_fetch("/users")
    if (!res.ok) {
      console.error(`Failed to fetch users`)
    }

    setUsers(res.value.data)
  }, [])

  // Handle data change to keep pagination state
  useEffect(() => {
    if (data.length < pagination.pageIndex * pagination.pageSize) {
      setPagination(prev => ({ ...prev, pageIndex: 0 }))
    }
  }, [data, pagination.pageIndex, pagination.pageSize])

  async function downloadBackupCsv() {
    try {
      const res = await api_fetch("/backup")
      if (!res.ok) {
        throw new Error("Failed to fetch data")
      }

      const filteredData = filterOutIdColumns(res.value.data)
      const csv = convertToCsv(filteredData)

      downloadCsv(csv, "backup")
    } catch (error) {
      console.error("Error downloading backup CSV:", error)
    }
  }

  function downloadDeductionsCsv(): void {
    const allColumns = table.getAllColumns()
    const headerRow = allColumns.map(column => `"${column.id}"`).join(",")

    const allRows = table.getCoreRowModel().rows

    const dataRows = allRows
      .map(row =>
        allColumns
          .map(column => {
            const cellValue = row.getValue(column.id)
            return `"${cellValue != null ? cellValue : ""}"`
          })
          .join(",")
      )
      .join("\n")

    const csv = [headerRow, dataRows].join("\n")

    downloadCsv(csv, "deductions")
  }

  const isRowSelected = Object.keys(rowSelection).length > 0

  const getTargetUrl = (row: any): string => {
    const TVI_PREFIXES = ["vcna", "vsup", "vcpn", "vonl", "viap"]
    if (
      TVI_PREFIXES.some(prefix => row.original.invoice_number?.toLowerCase().startsWith(prefix))
    ) {
      return `https://tvi.partnersonline.com/quick-search${row.original.invoice_number.replace(
        /[^0-9]/g,
        ""
      )}`
    } else if (row.original.invoice_number?.toLowerCase().startsWith("cb")) {
      return "https://apreports.partnersonline.com/credit_debit"
    } else {
      return "https://www.partnersonline.com/page/help/NDI0"
    }
    // return `/deduction/${row.original.id}`
  }

  const handleRowClick = (row: any, cell_id: string) => {
    const ROW_ACTION_COLUMNS = ["actions", "files", "select"]
    if (row.original.source?.toLowerCase() === "target") {
      ROW_ACTION_COLUMNS.push("invoice_number")
    }

    if (!ROW_ACTION_COLUMNS.some(action => cell_id.includes(action))) {
      if (row.original.source?.toLowerCase() === "target") {
        window.open(getTargetUrl(row), "_blank")
      } else {
        setLocation(`/deduction/${row.original.id}`)
      }
    }
  }

  const handleTaskAssignment = async (values: any) => {
    const selectedRows = table.getSelectedRowModel().rows
    let body = []
    for (const row of selectedRows) {
      body.push({
        deduction_id: row.original.id,
        user_id: values.user_id,
        task_type: values.taskType,
        // due_date: values.dueDate,
      })
    }

    let res = await api_fetch("/tasks", {
      method: "POST",
      body,
    })

    if (!res.ok) {
      console.error(`Failed to request backup`)
    }

    // todo: toast messages...
    StatusFlag.set(flag => !flag)
    setRowSelection({})
    setAssignmentPopoverOpen(false)
  }

  const bulkRequestBackup = async () => {
    if (requestBackupDisabled) return
    setRequestBackupDisabled(true)

    const selectedRows = table.getSelectedRowModel().rows
    const deductionForms = []
    for (const row of selectedRows) {
      let formData = new FormData()

      formData.append("deduction_id", row.original.id)
      formData.append("dispute_type_description", DISPUTE_TYPE_DESCRIPTIONS.request_backup)
      formData.append(
        "message",
        `Can you please provide backup for this charge? The deduction invoice number is ${row.original.invoice_number}. Thank you in advance!`
      )

      deductionForms.push(formData)
    }

    for (const deductionForm of deductionForms) {
      let res = await fetch("/api/dispute", {
        method: "POST",
        credentials: "same-origin",
        body: deductionForm,
      })

      if (!res.ok) {
        console.error(`Failed to request backup`)
      }
    }

    StatusFlag.set(flag => !flag)
    setRowSelection({})
    setRequestBackupDisabled(false)
  }

  const bulkUpdateStatus = async (status: String) => {
    const selectedRows = table.getSelectedRowModel().rows
    const selectedData = selectedRows.map(row => ({
      deduction_id: row.original.id,
      status,
      notes: "bulk",
    }))

    let res = await api_fetch("/deductions/statuses/bulk", {
      method: "POST",
      body: selectedData,
    })

    if (!res.ok) {
      throw new Error("Failed to mark validated")
    }

    StatusFlag.set(flag => !flag)
    setRowSelection({})
  }

  return (
    <>
      <div className="flex items-center justify-between py-4 pt-8">
        <div className="flex w-full items-center space-x-4">
          <div className="w-full mr-4">
            <DebouncedInput
              value={globalFilter ?? ""}
              onChange={value => handleChangeGlobalFilter(String(value))}
              className="p-2 font-lg"
              placeholder="Search..."
              debounce={300}
            />
          </div>
        </div>
        <div class="flex justify-end mr-3">
          <Button className="px-2 text-slate-500" onClick={downloadBackupCsv} variant="outline">
            <Download className="h-4" />
            Backup
          </Button>
        </div>
        <div class="flex justify-end mr-3">
          <Button className="px-2 text-slate-500" onClick={downloadDeductionsCsv} variant="outline">
            <Download className="h-4" />
            Deductions
          </Button>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto text-slate-500">
              <Columns2 className="h-4" />
              Columns
              <ChevronDown className="h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter(column => column.getCanHide())
              .map(column => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={value => column.toggleVisibility(!!value)}>
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <Table>
        <TableHeader className="bg-slate-50">
          {table.getHeaderGroups().map((headerGroup, idx) => (
            <TableRow key={`${headerGroup.id}-${idx}`}>
              {headerGroup.headers.map((header, headerIdx) => {
                return (
                  <TableHead
                    key={`${header.id}-${headerIdx}`}
                    className="first:rounded-tl-md last:rounded-tr-md">
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        {loading ? (
          <TableBody>
            <TableRow>
              {Array.from({ length: columns.length }).map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton className="w-[100px] h-[20px] rounded-full bg-slate-500" />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, idx) => (
                <TableRow key={`${row.id}-${idx}`} className={row.original['task'].task_user_id === user?.id && row.original['task'].task_status === 'pending' && `bg-plue-100`} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell, cellIdx) => (
                    <TableCell
                      className="hover:cursor-pointer"
                      onClick={() => handleRowClick(row, cell.id)}
                      key={`${cell.id}-${cellIdx}`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      {isRowSelected && (
        <div class="flex fixed bottom-10 z-10 bg-slate-900 rounded-lg border left-1/2 transform -translate-x-1/2 p-2">
          <div className="flex justify-start flex-grow">
            <Button onClick={() => setRowSelection({})} className="text-gray-400" variant="link">
              {`${table.getFilteredSelectedRowModel().rows.length} selected`}
              <X className="h-4" />
            </Button>
          </div>
          <Separator orientation="vertical" className="h-10 bg-slate-700 mr-3" />
          <div className="flex justify-end space-x-3">
            <Button
              className="pr-2 bg-orange-400-opacity-10"
              onClick={() => bulkRequestBackup()}
              disabled={requestBackupDisabled}>
              <span class="text-orange-500 ">Request backup</span>
              <File className="h-4 text-orange-500" />
            </Button>
            <Button
              className="pr-2 bg-sky-400-opacity-10"
              onClick={() => bulkUpdateStatus("disputable")}>
              <span class="text-sky-500">Mark disputable</span>
              <FileWarning className="h-4 text-sky-500" />
            </Button>
            <Button
              className="pr-2 bg-green-500-opacity-10"
              onClick={() => bulkUpdateStatus("validated")}>
              <span class="text-green-600">Mark validated</span>
              <Check className="h-4 text-green-600" />
            </Button>

            <Popover
              open={assignmentPopoverOpen}
              onOpenChange={() => setAssignmentPopoverOpen(true)}>
              <PopoverTrigger>
                {" "}
                <Button className="pr-2 bg-yellow-500-opacity-10">
                  <span class="text-yellow-600">Assign task</span>
                  <CircleCheck className="h-4 text-yellow-600" />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <AssignmentForm users={users} onSubmit={handleTaskAssignment} />
              </PopoverContent>
            </Popover>
          </div>
        </div>
      )}
      <div class="flex items-center justify-between p-2">
        <PageControls table={table} />
      </div>
    </>
  )
}
