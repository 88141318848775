import { useSearchParams } from "src/utils/util.tsx"
import { useLocation } from "wouter-preact"
import { api_fetch } from "../api/client.tsx"
import { User } from "../api/user.tsx"
import { DEFAULT_LOGGED_IN_PATH } from "../app/route.tsx"
import { useFormState } from "../component/form/form_state.tsx"
import { LoginScreen } from "../component/LoginScreen.tsx"
import { UserState } from "./user.tsx"

import { Logo } from "../app/logo.tsx"
import { Form } from "../component/form/Form.tsx"
import { EmailInput, PasswordInput } from "../component/form/Input.tsx"
import { Button } from "@/components/ui/button.tsx"

export function Login() {
  const [, setLocation] = useLocation()
  const form = useFormState({
    email: "",
    password: "",
  })

  const params = useSearchParams<{ next?: string; token?: string }>()
  const next = params.next ?? DEFAULT_LOGGED_IN_PATH

  async function onSubmit() {
    const res = await api_fetch<User>("/auth/login_password", {
      body: form.form_data,
    })
    if (!res.ok) {
      form.set_form_errors([res.error.message])
      return
    }
    UserState.set(res.value.data)
    setLocation(next)
  }

  return (
    <LoginScreen title="Log in to your account" logo={<Logo />}>
      <Form
        class="space-y-6"
        onSubmit={onSubmit}
        alerts={form.form_alerts}
      >
        <EmailInput
          label="Email address"
          required={true}
          bind={form.email}
        />
        <PasswordInput
          label="Password"
          required={false}
          bind={form.password}
        />
        <Button
          type="submit"
          className="w-full"
          variant="tertiary"
        >
          Log In
        </Button>
      </Form>
    </LoginScreen>
  )
}
