import { Uuid } from "src/utils/type"

export interface Integration {
  id: string
  name: string
  username: string
  // database schema
  schema_name: string
  data: { workspace_id: string }
}

export interface SyncTableData {
  table: string
  integration: string
  integration_id: Uuid
}

export interface JobSchedule {
  id: string
  crontab: string
  data: SyncTableData
  integration_id: Uuid
}

export interface Deduction {
  id: string 
  org_id: string
  remote_id: string
  created_at: string
  updated_at: string
  source: string
  invoice_amount: number
  discount_amount: number
  gross_amount: number
  invoice_date: string
  invoice_number: string
  check_amount: number
  check_date: string
  check_number: number
  category_id: string
  backup_s3_uri: string
  check_s3_uri: string
  po_number: string
  data: string
  document: string
  retailer_name: string | null
  execution_date: string
}

export interface DeductionNote {
  updated_at: string
  user_id: string
  message: string
  actor_name: string
}

export enum TaskStatus {
  Pending = "pending",
  Completed = "completed",
  Archived = "archived",
}

export enum TaskType {
  FileDispute = "file-dispute",
  PullBackup = "pull-backup",
  Followup = "followup",
  Validate = "validate",
  MapAccountingCode = "map-accounting-code",
}

export interface TaskInfo {
  task_type?: TaskType;
  task_status?: TaskStatus;
  task_user_id?: string; // Assuming Sid<User> is a string
  task_created_at?: string; // Assuming DateTime is a string
  task_assigner_email?: string;
  task_id?: string; // Assuming Sid<Task> is a string
  task_user_email?: string;
}

export interface DeductionResponse extends Deduction {
  category: string
  description: string
  status_value?: string 
  notes?: DeductionNote[]    
  task?: TaskInfo
}

export interface Backup {
  id: string // Sid<Backup>
  org_id: string // Sid<Organization>
  deduction_id?: string // Option<Sid<Deduction>>
  message_id?: string // Option<Sid<Message>>
  category_id?: string // Option<Uuid>
  s3_uri?: string // Option<String>
  created_at: Date // DateTime
  updated_at: Date // DateTime
  source: string // Source
  raw_data: any // Json<Value>

  invoice_number: string
  invoice_amount: number
  invoice_date?: string // Option<String>

  brand_name?: string
  vendor_remit_number?: string
  vendor_remit_name?: string

  promo_type?: string // mbc, scan, etc.
  promo_sub_type?: string // billing Type (billing type --> temp price adjustment, brand marketing, autoship & save)
  promo_period?: string

  customer?: string
  customer_code?: string
  customer_number?: string
  customer_city?: string
  customer_state?: string

  warehouse?: string
  inventory_number?: string

  start_date?: Date // Option<NaiveDate>
  end_date?: Date // Option<NaiveDate>

  total?: number
  discount_percent?: number
  unit_discount_amount?: number
  distributor_fee?: number

  product_quantity?: number
  product_description?: string
  product_unit_price?: number
  product_pack_size?: string
  product_number?: string

  retailer_name: string | null
  retailer_code: string | null
  quantity: number | null,
  unit_amount: number | null,
  received: string | null,

  po: string | null
  quantity_received: number | null

  execution_date: string | null

  upc: string | null
  carrier: string | null
  dc: string | null

}

export interface DisputeMessage {
  id: string;
  org_id: string;
  integration_id: string;

  from: string;
  remote_id: string; // Freshdesk ticket id
  body: string;
  sequence: number;
  status: string;

  sent_at: string; // Assuming DateTime is represented as a string

  deduction_id?: string;
  dispute_id?: string;
  attachment_s3_paths?: string[];
}

export interface EmailMessage {
  id: string;
  org_id: string;
  integration_id: string;
  remote_id: string;
  thread_id: string;
  sequence: number;
  date: string; // Assuming DateTime is a string representation
  from: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  // raw_data: any; // Assuming Json<gm::Message> can be represented as any
  attachments: {s3_uri: string, id: string}[];
  status?: string;
}

export interface Dispute {
  id: string
  org_id: string
  deduction_id?: string

  job_id?: string
  created_at: string
  updated_at: string
  source: string

  user_id: string
  amount: number

  dispute_type_description: string
  message: string
  dispute_file_s3_uris: string[]

  email_remote_id?: string
  email_thread_id?: string
}

