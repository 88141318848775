import {Card} from "@/components/ui/card.tsx";
import {Separator} from "@/components/ui/separator.tsx";
import {formatDistanceToNow} from "date-fns";
import {Eye, FilesIcon, PaperclipIcon} from "lucide-react";
import {api_fetch} from 'src/api/client.tsx';

export interface DeductionFile {
  display_name: string
  date: string
  s3_uri: string
  deduction_id: string
}

function formatFileSource(file: DeductionFile) {
  return file.display_name
}

export function handleViewS3Uri(s3_uri: string) {
  return function handleClick(e: MouseEvent) {
    e.preventDefault()
    window.open(`/api/_s3/${encodeURIComponent(s3_uri)}`, "_blank")
  }
}

interface FilesProps {
  files: DeductionFile[];
}

export function Files({files}: FilesProps) {
  if (!files.length) return null
  return (
    <Card className="h-64 mt-1.5 overflow-y-auto">
      <div class="flex justify-between items-center m-4">
        <h2 class="flex text-xl">
          <FilesIcon class="h-6 w-6"/>
          <span class="ml-2">Files</span>
        </h2>
      </div>
      <div class="px-4 w-full flex justify-center">
        <Separator class="w-1/2"/>
      </div>
      <div class="flex flex-col gap-4 mt-4 mb-4">
        {files.map((file, i) => (
          <>
            <div key={i} class="flex mt-2 gap-2 items-start ml-5 hover:cursor-pointer"
                 onClick={handleViewS3Uri(file.s3_uri)}>
              <div class="flex items-center ">
                <div className="inline-flex items-center justify-center border p-2 rounded-3xl">
                  <PaperclipIcon className="text-gray-800 h-5 w-5"/>
                </div>

                <div class="flex flex-col flex-grow ml-2">
                  <div class="text-lg ">
                    {formatFileSource(file)}
                  </div>
                  <span
                    class="text-gray-500">{`Uploaded ${formatDistanceToNow(new Date(file.date), {addSuffix: true})}`}</span>
                </div>
              </div>
              <div class="flex ml-auto mr-5 mt-3">
                <Eye className="text-gray-800 h-6 w-6"/>
              </div>
            </div>
          </>
        ))}
      </div>
    </Card>
  )
}
