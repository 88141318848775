import { Alerts } from "../Alerts.tsx"
import { Alert, ComponentProps, FormSubmitTarget } from "../type"

interface FormProps extends ComponentProps {
  alerts: Alert[] | null
  onSubmit: (e: FormSubmitTarget) => void
}

export function Form({ children, alerts, ...props }: FormProps) {
  function onSubmit(e: FormSubmitTarget) {
    e.preventDefault()
    props.onSubmit(e)
  }
  return <form {...props} onSubmit={onSubmit}>
    <Alerts alerts={alerts} class="text-red-400" />
    {children}
  </form>
}
