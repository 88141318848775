import { useState } from "preact/compat"
import { api_fetch } from "../../api/client.tsx"
import { useAsyncEffect, useSearchParams } from "../../utils/util.tsx"
import { MonthlyBreakdownChart } from "./monthly_breakdown.tsx"
import { columns } from "./sales_columns.ts"
import { DataTable } from "./table.tsx"
import { Link } from "wouter-preact";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from "@/components/ui/breadcrumb";

function SalesNavContext() {
  return (
    <div className="-mx-6 flex flex-col sm:gap-4 py-2 pb-4 max-w-fit">
      <header
        className="sticky top-0 z-30 flex h-4 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
        <Breadcrumb className="hidden md:flex">
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link href="/dashboard">Dashboard</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link href="/sales">Sales</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </header>
    </div>
  );
}

export function Sales() {
  const params = useSearchParams<{ search?: string, distributor?: string }>()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any[]>([])
  const [selectedDistributor, setSelectedDistributor] = useState(params.distributor || "All Distributors")

  useAsyncEffect(async () => {
    const res = await api_fetch<any[]>(`/sales`)

    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }

    setLoading(false)
    setData(res.value.data.map(d => Object.fromEntries(Object.entries(d).map(([k, v]) => [k, v ?? ""]))))
  }, [])

  return (
    <div>
      <SalesNavContext />
      <MonthlyBreakdownChart />
      <div class="mt-8">
        <DataTable loading={loading} columns={columns} data={data} />
      </div>
    </div>
  )
}
