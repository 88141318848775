import {ComponentChildren, VNode} from "preact"
import {useState} from "preact/compat"
import {Redirect, Route, Switch} from "wouter-preact"

import { BoxIntegration } from "src/setup/box.tsx"
import {SetupComplete} from "src/setup/complete.tsx"
import {EmailIntegration} from "src/setup/email.tsx";
import {SpsIntegration} from "src/setup/sps.tsx";
import {KeheIntegration} from "src/setup/kehe.tsx"
import { RawEmailIntegration } from 'src/setup/raw_email'
import {api_fetch} from "src/api/client.tsx"
import {User} from "src/api/user.tsx"
import {Login} from "src/auth/Login.tsx"
import {Logout} from "src/auth/Logout.tsx"
import {Signup} from "src/auth/Signup.tsx"
import {UserState} from "src/auth/user.tsx"
import {Dashboard} from "src/dashboard/dashboard.tsx"
import {Landing} from "src/landing/landing.tsx"
import {useAsyncEffect} from "src/utils/util.tsx"
import { Deductions } from "src/dashboard/deductions"
import {Settings} from "src/settings/index.tsx"
import { DeductionDetail } from "@/dashboard/deductions/detail"
import { Sales } from '@/dashboard/sales';
import { FreshdeskIntegration } from '../setup/freshdesk';


export const DEFAULT_LOGGED_IN_PATH = "/deductions"

interface AuthenticatedProps {
  user: User | null
  children: ComponentChildren
}

export function Authenticated({children, user}: AuthenticatedProps): VNode {
  const [loading, setLoading] = useState(!user)

  useAsyncEffect(async () => {
    let res = await api_fetch<User>("/auth/me")
    if (!res.ok) {
      setLoading(false)
      return
    }
    if (res.value.data != user) {
      UserState.set(res.value.data)
    }
    setLoading(false)
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }
  if (!user) {
    let url = window.location.pathname
    url = "/login?" + new URLSearchParams({next: url}).toString()
    return <Redirect to={url}/>
  }
  return children as VNode
}

export function Routes() {
  const user = UserState.use()

  return (
    <Switch>
      <Route path="/" component={Landing}/>
      <Route path="/login" component={Login}/>
      <Route path="/signup" component={Signup}/>
      <Route path="/logout" component={Logout}/>

      <Authenticated user={user}>
        <Route path="/setup/kehe" component={KeheIntegration}/>
        <Route path="/setup/freshdesk" component={FreshdeskIntegration}/>
        <Route path="/setup/sps" component={SpsIntegration} />
        <Route path="/setup/email" component={EmailIntegration}/>
        <Route path="/setup/email2" component={RawEmailIntegration}/>
        <Route path="/setup/box" component={BoxIntegration} />
        <Route path="/setup/complete" component={SetupComplete}/>
        
        {/* TODO: not redirecting to login even if logged out when hitting /deductions */}
        <Dashboard>
          <Route path="/deductions" component={Deductions}/>
          <Route path="/deduction/:deduction_id" component={DeductionDetail}/>
          <Route path="/sales" component={Sales}/>
          <Route path="/settings" component={Settings}/>
          <Route path="/:rest*">Not Found</Route>
        </Dashboard>
      </Authenticated>
    </Switch>
  )
}
