import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ChartContainer, ChartLegendContent, ChartTooltipContent } from '@/components/ui/chart';
import { format, parseISO, addDays } from 'date-fns';
import { useEffect, useState } from 'preact/hooks';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { api_fetch } from 'src/api/client.tsx';
import { formatDistributor } from 'src/utils/util.tsx';
import { QueryData, TitleArea } from '../common';

interface RawData {
  month: string
  distributor: string
  total_sales: number
  total_deductions: number
}

interface ChartData {
  month: string
  [key: string]: string | number
}

export function MonthlyBreakdownChart() {
  const [rawData, setRawData] = useState<RawData[]>([])
  const [chartData, setChartData] = useState<ChartData[]>([])
  const [distributors, setDistributors] = useState<string[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        let qs = new URLSearchParams()
        qs.set("days", '365')
        qs.set("distributor", '')
        qs.set("query_name", "monthly_breakdown")
        let res = await api_fetch<QueryData>(`/deductions/query?${qs}`)

        if (!res.ok) {
          throw new Error('Failed to fetch data')
        }

        const headerIndex = res.value.data.headers.reduce((acc, header, index) => {
            acc[header] = index;
            return acc;
          }, {} as { [key: string]: number });

        const data = res.value.data.rows.map(row => {
          const parsedDate = parseISO(row[headerIndex.month] as string);
          const correctedDate = addDays(parsedDate, 1); // Adjust for off-by-one error
          return {
            month: format(correctedDate, 'MMM yyyy'),
            distributor: row[headerIndex.distributor] as string,
            total_sales: Number(row[headerIndex.total_sales]),
            total_deductions: Number(row[headerIndex.total_deductions]),
          }
        });

        setRawData(data)
        setDistributors([...new Set(data.map(item => item.distributor))])
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const processData = () => {
      const grouped: { [key: string]: ChartData } = {}

      rawData.forEach(item => {
        if (!grouped[item.month]) {
          grouped[item.month] = { month: item.month }
        }
        grouped[item.month][`${item.distributor}_sales`] = item.total_sales
        grouped[item.month][`${item.distributor}_deductions`] = item.total_deductions
      })

      setChartData(Object.values(grouped))
    }

    processData()
  }, [rawData])

  if (loading) {
    return <div>Loading...</div>
  }

  const colors = {
    kehe: {
      sales: "#81c784",    // green-300
      deductions: "#388e3c" // green-700
    },
    unfi: {
      sales: "#4db6ac",    // teal-300
      deductions: "#009688" // teal-700
    },
    target: {
      sales: "#ef9a9a",    // red-300
      deductions: "#e53935" // red-700
    }
  }

  const getColor = (distributor: string, type: 'sales' | 'deductions') => {
    if (distributor.toLowerCase() in colors) {
      return colors[distributor.toLowerCase() as keyof typeof colors][type];
    }
    // Fallback colors for any other distributors
    return type === 'sales' ? "#8884d8" : "#0088fe";
  }

  const chartConfig = distributors.reduce((config, distributor, index) => {
    const formattedDistributor = formatDistributor(distributor);
    config[`${distributor}_sales`] = { label: `${formattedDistributor} Sales`, color: getColor(distributor, 'sales') }
    config[`${distributor}_deductions`] = { label: `${formattedDistributor} Deductions`, color: getColor(distributor, 'deductions') }
    return config
  }, {} as any)

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <Card>
      <CardHeader>
        <TitleArea
          title="Monthly Sales vs Deductions by Distributor"
          titleTooltip="Showing total sales and deductions for each distributor over the last 12 months"
        />
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart width={600} height={300} data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis 
              dataKey="month" 
              tickLine={false} 
              tickMargin={10} 
              axisLine={false} 
              tickFormatter={(value) => value.slice(0, 3)} 
            />
            <YAxis tickFormatter={(value) => currencyFormatter.format(value)} />
            <Tooltip 
              content={<ChartTooltipContent hideLabel={true} />} 
            />
            <Legend content={<ChartLegendContent payload={chartData} verticalAlign="top" />} />
            {distributors.flatMap((distributor) => {
              const formattedDistributor = formatDistributor(distributor);
              return [
                <Bar 
                  key={`${distributor}_sales`} 
                  dataKey={`${distributor}_sales`} 
                  stackId="a" 
                  fill={getColor(distributor, 'sales')} 
                  radius={[0, 0, 4, 4]} 
                  name={`${formattedDistributor} Sales`}
                />,
                <Bar 
                  key={`${distributor}_deductions`} 
                  dataKey={`${distributor}_deductions`} 
                  stackId="a" 
                  fill={getColor(distributor, 'deductions')} 
                  radius={[4, 4, 0, 0]} 
                  name={`${formattedDistributor} Deductions`}
                />
              ];
            })}
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}