import { createDateColumn, DeductionTableColumn, distributorColumn, filesColumn, invoiceAmountColumn, invoiceNumberColumn } from "../deductions/table/columns"

export const columns: DeductionTableColumn[] = [
  createDateColumn("invoice_date", "Invoice Date"),
  invoiceNumberColumn,
  invoiceAmountColumn,
  distributorColumn,
  filesColumn,
  createDateColumn("check_date", "Check Date"),
//   todo: difference between check date and invoice date in days or is that not the payment date??
]