import { useEffect, useState } from "preact/compat"

const testimonials = [
  {
    quote:
      "I used to dread doing deductions work, but now I actually enjoy how efficient I can be with Promoted and celebrating the repayments when they come through.",
    author: "Rachel Bower",
    title: "Sr. Director of Planning at Bellway",
    avatar: "/img/testimonials/rachel.png",
    company: "/img/testimonials/bellway.png",
  },
  {
    quote:
      "What this gives me more than anything is peace of mind. With Promoted, now at least I know where my money is going and that we are doing our best to win back what is ours.",
    author: "Jon Reed",
    title: "CEO at Compostic",
    avatar: "/img/testimonials/jon.jpeg",
    company: "/img/testimonials/compostic.png",
  },
  {
    quote:
      "I see Promoted becoming the operating system for CPG brands. They're dialed in on the most important problem first though, which is deductions.",
    author: "Jim DeCicco",
    title: "Co-founder at Super Coffee",
    avatar: "/img/testimonials/jim.png",
    company: "/img/testimonials/super.png",
  },
  {
    quote:
      "Nat and the team care about their mission of helping brands so much, and I'm grateful to work with people whose passion matches mine.",
    author: "Sophia Maroon",
    title: "CEO at Dress It Up Dressing",
    avatar: "/img/testimonials/sophia.png",
    company: "/img/testimonials/dressitup.png",
  },
  {
    quote:
      "I was unsure that an upstart company could handle the complexity of our deductions, but Promoted met our needs perfectly and it has been a game changer for us.",
    author: "Walter Gordon",
    title: "Global Vice President at Diageo",
    avatar: "/img/testimonials/walter.png",
    company: "/img/testimonials/diageo.png",
  },
  {
    quote:
      "I'm continuously blown away at how quickly the team improves the platform with new integrations and features. I feel so grateful to have found Promoted when I did.",
    author: "JP Mackey",
    title: "President at Scharffen Berger Chocolates",
    avatar: "/img/testimonials/jp.png",
    company: "/img/testimonials/scharffen.png",
  },
]

export function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % testimonials.length)
    }, 5000) // Change quote every 5 seconds

    return () => clearInterval(timer)
  }, [])

  const currentTestimonial = testimonials[currentIndex]

  return (
    <div className=" p-8 rounded-lg relative overflow-hidden pt-24">
      <div className="max-w-3xl mx-auto text-center relative z-10">
        <div className="flex justify-center mb-4">
          {[...Array(5)].map((_, i) => (
            <svg key={i} className="w-6 h-6 text-plue-900" fill="currentColor" viewBox="0 0 20 20">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          ))}
        </div>

        <div className="h-32 flex items-center justify-center">
          <p className="text-2xl font-semibold text-plue-800 mb-6">{currentTestimonial.quote}</p>
        </div>

        <div className="mb-8">
          <p className="font-bold text-plue-900">{currentTestimonial.author}</p>
          <p className="text-plue-700">{currentTestimonial.title}</p>
          <div class="flex justify-center mt-4">
            <img src={currentTestimonial.company} className="w-24 min-h-32 object-cover" />
          </div>
        </div>

        <div className="flex justify-center space-x-2">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`w-12 h-12 rounded-full border-2 ${
                index === currentIndex ? "border-plue-600" : "border-transparent"
              } overflow-hidden`}
              onClick={() => setCurrentIndex(index)}>
              <img
                src={testimonial.avatar}
                alt={`Avatar ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
