import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Icon, Title } from "@tremor/react"
import { Link } from "wouter-preact"

const FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const DATE_FORMATTER = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
})

// whole dollar amounts, no cents
export const currencyFormatter = (number: number) => FORMATTER.format(Math.round(number)).toString().slice(0, -3)

export const exactCurrencyFormatter = (number: number) => FORMATTER.format(number)

export const dateFormatter = function(date: string | undefined, formatter = DATE_FORMATTER): string {
  if (!date) {
    return ""
  }
  try {
    return formatter.format(new Date(date))
  } catch (e) {
    console.warn(`Could not format data as date: ${date}`)
    return date
  }
}

export type Cell = string | number | boolean

export interface QueryData {
  headers: string[]
  rows: Cell[][]
}

export interface TitleAreaProps {
  title: string
  link?: string
  titleTooltip?: string
}

export function TitleArea({ title, link, titleTooltip }: TitleAreaProps) {
  return <div class="flex">
    <Title>
      {link ? <Link to={link}>{title}</Link> : title}
    </Title>
    {titleTooltip && <Icon
      color="gray"
      icon={InformationCircleIcon}
      variant="simple"
      tooltip={titleTooltip}
    />}
  </div>
}

export interface Data<T extends any[]> {
  headers: string[]
  rows: T[]
}