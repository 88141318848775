import { ComponentType, VNode } from "preact"
import { Link, RouteProps, Switch, useLocation } from "wouter-preact"
import { classes } from "../utils/util.tsx"

import {
  // Calendar,
  // LineChart,
  CircleDollarSignIcon,
  FileWarning,
  Home,
  LogOut,
  Settings,
} from "lucide-react"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"

export function Header() {
  return (
    <div className="flex flex-col sm:gap-4 py-2 max-w-fit">
      <header className="sticky top-0 z-30 flex h-4 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
        <Breadcrumb className="hidden md:flex">
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link href="#">Dashboard</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Deductions</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </header>
    </div>
  )
}

interface SidebarItemProps {
  href: string;
  icon: ComponentType<Omit<React.SVGProps<SVGSVGElement>, 'size'>>; // Adjusted to omit 'size' prop
  tooltip: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ href, icon: Icon, tooltip }) => {
  const [location] = useLocation();
  const isSelected = location.toLowerCase().includes(href.toLowerCase());
  const queryParams = location.split('?')[1] || '';

  return (
    <Tooltip>
      <TooltipTrigger>
        <a
          href={`${href}?${queryParams}`}
          className={classes(
            "flex h-9 w-9 items-center justify-center text-muted-foreground transition-colors hover:text-plue-900 md:h-8 md:w-8",
            isSelected ? "border-l-4 border-plue-900 -ml-6" : ""
          )}>
          <div className={classes(isSelected ? 'ml-6' : '')}>        
            <Icon className={classes("h-5 w-5", `${isSelected ? 'text-plue-900' : ''}`)}/>
          </div>
          <span className="sr-only">{tooltip}</span>
        </a>
      </TooltipTrigger>
      <TooltipContent side="right">{tooltip}</TooltipContent>
    </Tooltip>
  )
}

export function Sidebar() {
  
  return (
      <div className="fixed inset-y-0 left-0 z-10 w-14 flex flex-col border-r bg-background sm:flex">
            <TooltipProvider delayDuration={0}>
        <div className="flex flex-col items-center gap-4 px-2 sm:py-5">
          <Link href="/deductions">
            <img class="block h-8 w-8" src="/img/promoted.png" alt="Promoted" />
            <span className="sr-only">Promoted</span>
          </Link>
          <SidebarItem href="#" icon={Home} tooltip="Dashboard" />
          <SidebarItem href="/deductions" icon={FileWarning} tooltip="Deductions" />
          <SidebarItem href="/sales" icon={CircleDollarSignIcon} tooltip="Sales" />
          {/* <SidebarItem href="#" icon={Calendar} tooltip="Trade planning" />
          <SidebarItem href="#" icon={LineChart} tooltip="Analytics" /> */}
        </div>
        <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
          <SidebarItem href="/settings" icon={Settings} tooltip="Settings" />
          <SidebarItem href="/logout" icon={LogOut} tooltip="Sign out" />
        </nav>
      </TooltipProvider>
      </div>
  )
}

export function Dashboard({ children }: { children: Array<VNode<RouteProps>> }) {
  const [location, _] = useLocation()

  return (
    <div class="flex w-full flex-col min-h-screen">
      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
        <Sidebar />
        {location === '/deductions' && <Header />}
        <main class="mx-8 max-w-full ">
          <Switch children={children} />
        </main>
      </div>
    </div>
  )
}
