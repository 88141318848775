import { Badge } from "@/components/ui/badge"
import { Logo } from "src/app/logo.tsx"
import { DEMO_LINK } from "./landing"

export function Header() {
  return (
    <div class="justify-center max-w-7xl mx-auto">
      <div className="mt-4 border rounded-2xl border-plue-600 bg-plue-400 bg-opacity-70  ">
        <div className="flex items-center justify-between p-2 mx-auto ">
          <div>
            <a href="/" className="flex items-center space-x-3">
              <Logo />
            </a>
          </div>
          <div>
            <a href="/login" className="mr-4 text-sm font-semibold leading-6 text-white">
              Log in
            </a>
            <a href={DEMO_LINK} target="_blank" className="btn btn-tertiary p-2 rounded-xl text-sm">
              Book a demo
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export function Interlude({
  badgeCopy,
  title,
  description,
}: {
  badgeCopy: string
  title: string
  description: string
}) {
  return (
    <div className="bg-plue-100 flex flex-col items-center justify-center px-4 text-plue-900">
      <Badge variant="secondary" className="mb-8">
        {badgeCopy}
      </Badge>

      <h1 className="text-5xl font-bold text-center mb-4">{title}</h1>

      <p className="text-xl text-center text-plue-700 mb-16 max-w-2xl">{description}</p>
    </div>
  )
}

export function Screenshot({ src, alt }: { src: string; alt: string }) {
  return (
    <div className="flex justify-center mx-8">
      <img src={src} alt={alt} className="h-auto rounded-lg shadow-lg max-w-7xl z-10" />
    </div>
  )
}

export function Footer() {
  return (
    <div class="justify-center max-w-7xl mx-auto">
      <div className="mb-4 border rounded-2xl border-plue-600 bg-plue-400 bg-opacity-70">
        <div className="flex items-center justify-between p-2">
          <a href="/" className="flex items-center space-x-3">
            <Logo />
          </a>
          <footer className="text-plue-900">{`© Promoted ${new Date().getUTCFullYear()}. All rights reserved.`}</footer>
        </div>
      </div>
    </div>
  )
}

export function Squiggles() {
  return (
    <div>
      <img
        src="/img/landing/squiggle1.svg"
        alt="Squiggle"
        className="hidden md:block absolute top-18 right-0 w-72 h-auto"
      />
      <img
        src="/img/landing/squiggle4.svg"
        alt="Squiggle"
        className="hidden md:block absolute top-1/3 left-0 w-36 h-auto z-0 rotate-45"
      />

      <img
        src="/img/landing/squiggle4.svg"
        alt="Squiggle"
        className="hidden md:block absolute top-[1500px] right-0 w-36 h-auto z-0 rotate-45"
      />
      <img
        src="/img/landing/squiggle1.svg"
        alt="Squiggle"
        className="hidden md:block absolute top-[1600px] left-0 w-48 h-auto"
      />

      <img
        src="/img/landing/squiggle3.svg"
        alt="Squiggle"
        className="hidden md:block absolute top-[3240px] right-0 w-36 h-auto z-0"
      />
      <img
        src="/img/landing/squiggle4.svg"
        alt="Squiggle"
        className="hidden md:block absolute top-[3340px] left-0 w-36 h-auto z-0"
      />

      <img
        src="/img/landing/squiggle4.svg"
        alt="Squiggle"
        className="hidden md:block absolute top-[4500px] left-0 w-36 h-auto z-0 rotate-45"
      />
      <img
        src="/img/landing/squiggle1.svg"
        alt="Squiggle"
        className="hidden md:block absolute top-[4350px] right-0 w-48 h-auto"
      />
    </div>
  )
}
