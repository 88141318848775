"use client"

import { DeductionResponse } from "@/api/integration";
import { Badge } from "@/components/ui/badge";

import { Checkbox } from "@/components/ui/checkbox";
import { cellCurrencyFormatter, cellDateFormatter } from "@/dashboard/deductions/detail/backup_columns.tsx";
import { handleViewS3Uri } from "@/dashboard/deductions/detail/files.tsx";
import {
  AccessorColumnDef,
  DisplayColumnDef,
  GroupColumnDef,
  RowSelectionInstance
} from "@tanstack/react-table";
import { capitalCase } from "change-case";

import { classes } from "@/utils/util.tsx";
import {
  CircleCheckIcon,
  Dot,
  Ellipsis,
  Eye,
  File,
  FileCheck,
  FileCog,
  Paperclip,
  Receipt,
  ShieldQuestion,
  Trophy,
} from "lucide-react";
import { formatDistributor } from 'src/utils/util.tsx';
import { ColumnHeader } from "./column_header";
import FileDisputeDrawer from "./file_dispute_drawer";

export const statusIcons = {
  new: <Dot className="h-4 w-4 text-orange-700"/>,
  review: <Eye className="h-4"/>,
  validated: <CircleCheckIcon fill="green" className="h-4 text-white"/>,
  won: <Trophy className="h-4"/>,
  pending: <Ellipsis className="h-4"/>,
  disputed: <Ellipsis className="h-4"/>,
  backup_requested: <FileCog className="h-4"/>,
  backup_received: <FileCheck className="h-4"/>,
  processing: <Receipt className="h-4"/>,
  disputable: <ShieldQuestion className="h-4"/>,
}

export const CATEGORY_COLORS = {
  "Retailer Promotion": "amber",
  "Operational Issues": "emerald",
  "Free Fill": "fuchsia",
  "Distributor Promotion": "sky",
  "Contractual Fees": "rose",
  "Misc": "slate",
  "Freight": "teal",
  "Invoice Discrepancy": "lime",
  "Slotting": "indigo",
}

export const invoiceAmountColumn: DeductionTableColumn =   {
  accessorKey: "invoice_amount",
  enableHiding: false,
  enableGlobalFilter: false,
  header: ({ column }) => <ColumnHeader className="justify-end" column={column} title="Amount" />,
  cell: cellCurrencyFormatter,
}

export const invoiceNumberColumn: DeductionTableColumn = {
  accessorKey: "invoice_number",
  enableHiding: false,
  header: ({column}) => <ColumnHeader column={column} title="Invoice #"/>,
}

export const createDateColumn = (accessorKey: string, title: string): DeductionTableColumn => ({
  accessorKey: accessorKey as keyof DeductionResponse,
  enableHiding: false,
  enableGlobalFilter: false,
  header: ({ column }) => <ColumnHeader column={column} title={title} />,
  cell: cellDateFormatter,
})

export const distributorColumn: DeductionTableColumn = {
  accessorKey: "source",
  enableHiding: false,
  header: ({ column }) => <ColumnHeader column={column} title="Dist" />,
  cell: cell => {
    let value = cell.getValue()
    if (!value) return ""
    return formatDistributor(value as string)
  }
}

export const filesColumn: DeductionTableColumn = {
  id: "files", // named this so it's consistent in the column dropdown filter
  header: ({column}) => <ColumnHeader column={column} title="Files"/>,
  enableGlobalFilter: false,
  cell: cell => {
    let buttons = []
    let backup_s3_uri = cell.row.original.backup_s3_uri
    if (backup_s3_uri) {
      buttons.push(
        <div onClick={handleViewS3Uri(backup_s3_uri)}
             className="cursor-pointer text-primary underline pr-2">
          <Paperclip className="w-4 h-4"/>
        </div>
      )
    }
    let check_s3_uri = cell.row.original.check_s3_uri
    if (check_s3_uri) {
      buttons.push(
        <div onClick={handleViewS3Uri(check_s3_uri)}
             className="cursor-pointer text-primary underline pr-2">
          <File className="w-4 h-4"/>
        </div>
      )
    }
    if (!buttons.length) return ""
    return (
      <div class="flex">
        {buttons}
      </div>
    )
  },
}

export type TableColumn<T> =
  | DisplayColumnDef<T, unknown>
  | GroupColumnDef<T, unknown>
  | AccessorColumnDef<T, unknown> & {
  accessorKey: keyof T
}

type DeductionTableColumn = TableColumn<DeductionResponse>;

export const columns: DeductionTableColumn[] = [
  {
    id: "select",
    header: ({table}: { table: RowSelectionInstance<DeductionResponse> }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({row}) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={value => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
    enableGlobalFilter: false,
  },
  createDateColumn("invoice_date", "Invoice Date"),
  invoiceNumberColumn,
  distributorColumn,
  {
    accessorKey: "category",
    header: ({column}) => <ColumnHeader column={column} title="Category"/>,
    cell: cell => {
      let value = cell.getValue() as "" | keyof typeof CATEGORY_COLORS
      if (!value) return ""
      let color = CATEGORY_COLORS[value]
      return value ? (
        <Badge
          className={classes(
            `bg-${color}-100 text-${color}-700 hover:bg-${color}-200`,
            "text-center"
          )}>
          {capitalCase(value)}
        </Badge>
      ) : (
        ""
      )
    },
  },
  {
    accessorKey: "description",
    header: ({column}) => <ColumnHeader column={column} title="Description"/>,
  },
  {
    accessorKey: "retailer_name",
    header: ({column}) => <ColumnHeader column={column} title="Retailer"/>,
  },
  invoiceAmountColumn,
  {
    accessorKey: "check_amount",
    enableGlobalFilter: false,
    header: ({column}) => (
      <ColumnHeader className="justify-end" column={column} title="Check Amount"/>
    ),
    cell: cellCurrencyFormatter,
  },
  createDateColumn("execution_date", "Execution Date"),
  {
    accessorKey: "status_value",
    header: ({column}) => <ColumnHeader column={column} title="Stage"/>,
    cell: cell => {
      // todo: distinguish between null where that means default requires review vs a type of deduction that doesn't need to be reviewed (e.g. PayStatusCode === "P - Paid")
      // todo: show which deductions have been disputed - won / lost (ideally)
      // write a script to backfill these values?? but for now just show "HasDispute"
      // @ts-ignore
      let value = cell.getValue("status_value") || "new"
      // TODO(joey): update this or write the backfill script...no longer returned with the Deduction object
      // @ts-ignore
      if (cell.row.original["HasDispute"]) {
        value = "disputed"
      }
      // @ts-ignore
      if (cell.row.original["PayStatusCode"]?.toLowerCase().includes("in progress")) {
        value = "" // "processing"
      }
      // @ts-ignore
      const Icon = statusIcons[value.toLowerCase()]
      return value ? (
        <Badge className="pl-1 rounded-sm text-center" variant={value.toLowerCase()}>
          {Icon}
          {capitalCase(value)}
        </Badge>
      ) : (
        ""
      )
    },
  },
  filesColumn,
  {
    id: "actions",
    header: ({column}) => <ColumnHeader column={column} title=""/>,
    enableGlobalFilter: false,
    cell: ({row}) => {
      return <FileDisputeDrawer row={row}/>
    },
  },
  {
    id: 'task',
    accessorFn: row => `${row.task?.task_status}: ${row.task?.task_user_email?.split("@")[0]}`,
    header: ({column}) => <ColumnHeader column={column} title="Task"/>,
    enableSorting: true,
    cell: ({row}) => {
      let task_user_email = row.original.task?.task_user_email
      if (!task_user_email) return ""

      return `${row.original.task?.task_status}: ${task_user_email.split("@")[0]}`  
    },
  }
]
