import { Err, Ok, Result } from "../utils/type.tsx"

interface GetOptions {
  method?: "GET"
  use_cache?: boolean
}

interface DeleteOptions {
  method: "DELETE"
  use_cache?: false
}

interface RestDataOptions {
  method?: "POST" | "PUT"
  body: object
  use_cache?: boolean
}

export type RestOptions = GetOptions | RestDataOptions | DeleteOptions

export interface HttpResponse<T> {
  data: T
  status: number
  headers: Headers
}

export class HttpError extends Error {
  status: number | null

  constructor(message: string, status: number | null) {
    super(message)
    this.status = status
  }
}

const REQUEST_CACHE: Record<string, [Result<HttpResponse<any>, HttpError>, number]> = {}

export async function api_fetch<T>(path: string, opt?: RestOptions): Promise<Result<HttpResponse<T>, HttpError>> {
  let options = opt ?? {}
  let res
  let cacheKey: string = JSON.stringify([path, options])
  if (!!options.use_cache) {
    let cached = REQUEST_CACHE[cacheKey]
    if (cached) {
      let [result, timestamp] = cached
      if (Date.now() - timestamp < 1000 * 60 * 5) {
        return result
      }
    }
  }
  try {
    res = await fetch("/api" + path, {
      method: options.method ?? ("body" in options ? "POST" : "GET"),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "same-origin",
      body: "body" in options ? JSON.stringify(options.body) : null,
    })
  } catch (e) {
    // the request timed out or we couldn't connect to the server at all (e.g. server is down).
    return Err(
      new HttpError("There is a problem with your internet connection. Check your connection and try again.", null),
    )
  }
  const content = await res.text()
  let message
  let data
  try {
    data = JSON.parse(content)
    message = data.message
  } catch (e) {
  }
  // add to cache
  if (res.status >= 500) {
    message = message ?? "There was a server error. Please try again later."
    return Err(new HttpError(message, res.status))
  } else if (res.status >= 400) {
    message = message ?? "There was a client error."
    return Err(new HttpError(message, res.status))
  } else {
    let result = Ok({
      data,
      status: res.status,
      headers: res.headers,
    })
    if (!!options.use_cache) {
      REQUEST_CACHE[cacheKey] = [result, Date.now()]
    }
    return result
  }
}
