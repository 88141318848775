import {BarList} from "@tremor/react"
import {useState} from "preact/compat"
// @ts-ignore
import {AnchorHTMLAttributes, MouseEvent} from "react";
import {useLocation} from "wouter-preact";
import {api_fetch} from "../../api/client.tsx"
import {useAsyncEffect} from "../../utils/util.tsx"
import {currencyFormatter, QueryData} from "../common.tsx"
import {LoadingSpinner} from "./summary_cards.tsx"
import {capitalCase} from "change-case"

type DeductionCategoryData = {
  name: string
  value: number
  amount: number,
}

export const STATUS_COLORS: { [key: string]: string } = {
  "new": "red",
  "validated": "green",
  "disputable": "yellow",
  "review": "yellow",
  "backup_requested": "slate",
  "backup_received": "orange",
  "disputed": "slate",
  "processing": "slate",
  "won": "green",
  "misc": "slate",
}

export const SORT_ORDER = {
  "new": 0,
  "validated": 1,
  "disputable": 2,
  "backup_requested": 3,
  "backup_received": 4,
  "review": 5,
  // don't need to work on it
  "disputed": 6,
  "processing": 7,
  "won": 11,
  "misc": 12, // TODO kill this
}

export function StatusBarChart({selectedDay, selectedDistributor}: {
  selectedDay: number,
  selectedDistributor: string
}) {
  const [data, setData] = useState<DeductionCategoryData[]>([])
  const [loading, setLoading] = useState(true)
  const [nudge, setNudge] = useState(0)
  const [, setLocation] = useLocation()

  useAsyncEffect(async () => {
    let qs = new URLSearchParams()
    qs.set("days", selectedDay.toString())
    const distributor = selectedDistributor === "All Distributors" ? "" : selectedDistributor
    qs.set("distributor", distributor)
    qs.set("query_name", "status")
    let res = await api_fetch<QueryData>(`/deductions/query?${qs}`)
    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }
    setLoading(false)

    const headerIndex = res.value.data.headers.reduce((acc, header, index) => {
      acc[header] = index
      return acc
    }, {} as { [key: string]: number })

    let total = res.value.data.rows.map(row => row[headerIndex.deduction_count] as number).reduce((a, b) => a + b, 0)
    let nudge = total * 0.30
    setNudge(nudge)
    let data = res.value.data.rows.map(row => {
      let amount = row[headerIndex.total_dollar_amount] as number
      let count = row[headerIndex.deduction_count] as number
      return {
        key: row[headerIndex.status_value] as keyof typeof SORT_ORDER,
        amount: amount,
        name: `${capitalCase(row[headerIndex.status_value] as string)} (${count})`,
        value: count + nudge,
        href: `?search=${row[headerIndex.status_value]}&days=${selectedDay}&distributor=${distributor}`,
        color: STATUS_COLORS[row[headerIndex.status_value] as keyof typeof STATUS_COLORS] || "gray",
        target: "",
      }
    })
    data.sort((a, b) => SORT_ORDER[a.key] - SORT_ORDER[b.key])
    setData(data)
  }, [selectedDay, selectedDistributor])

  if (loading || !data) {
    return <LoadingSpinner color="blue"/>
  }

  return (
    <div class="flex">
      <BarList data={data} className="max-w-2xl grow" valueFormatter={(n) => ""}/>
      <div class="xl:ml-6">
        {data.map((d, i) => {
          return <div class="h-9 mb-2 items-center flex">{currencyFormatter(d.amount)}</div>
        })}
      </div>
    </div>
  )
}