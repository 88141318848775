import { useState } from "preact/compat"
import { updateSearchParams, useAsyncEffect, useSearchParams } from "../../utils/util.tsx"
import { api_fetch } from "../../api/client.tsx"
import {Deduction, DeductionResponse} from "../../api/integration.tsx"
import { SummaryCards } from "./summary_cards.tsx"
import { GenericFilter } from "../component/filter.tsx"
import { columns } from "./table/columns.tsx"
import { DataTable } from "./table/data_table.tsx"
import { StatusFlag } from "./status_state"
import { useLocation } from "wouter-preact"

export const DISPUTE_TYPE_DESCRIPTIONS = {
  // one of:
  // "Product Shortage"
  // "Price Change Dispute"
  // "Off Invoice Deduction"
  // "Cash Discount"
  // "Backup Request"
  // "Payment Status"
  // "Chargeback Deduction"
  // "Audit Deduction"
  // "Vendor Statement"
  // "Other"
  request_backup: "Backup Request",
  chargeback_deduction: "Chargeback Deduction",
}

export function Deductions() {
  const [, setLocation] = useLocation()
  const params = useSearchParams<{ search?: string, days?: number, distributor?: string }>()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<DeductionResponse[]>([])
  const statusFlag = StatusFlag.use(sf => sf!)

  const distributors = ["All Distributors", "KeHE", "UNFI", "Target"]
  const dayCounts = [7, 14, 21, 30, 60, 90, 120, 150, 180, 365, 500]
  const [selectedDay, setSelectedDay] = useState(params.days || dayCounts[8])
  const [selectedDistributor, setSelectedDistributor] = useState(distributors[0])

  const handleDayCountChange = (day: number) => {
    setSelectedDay(day)
    let qs = updateSearchParams(params, [{key: "days", value: day.toString()}])
    setLocation(`/deductions?${qs.toString()}`, { replace: true })
  }

  const handleDistributorChange = (distributor: string) => {
    setSelectedDistributor(distributor)
  }

  const dayCountToString = (item: number) => {
    if (item > 365) {
      return "All time"
    } else if (item === 365) {
      return "Past year"
    } else if (item < 365 && item > 30) {
      return `Past ${item / 30} months`
    } else {
      return `Past ${item} days`
    }
  }

  useAsyncEffect(async () => {
    let qs = updateSearchParams(params, [{key: "days", value: selectedDay.toString()}, {key: "distributor", value: selectedDistributor === "All Distributors" ? "" : selectedDistributor}])
    const res = await api_fetch<Deduction[]>(`/deduction?${qs.toString()}`)

    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }

    setLoading(false)

    // convert null values to empty strings to ensure filtering works as expected
    const data = res.value.data.map(d => {
      return Object.fromEntries(Object.entries(d).map(([k, v]) => [k, v ?? ""])) as DeductionResponse
    })
    setData(data)
  }, [selectedDay, statusFlag, selectedDistributor])

  return (
    <div>
      <GenericFilter
        items={distributors}
        selectedItem={selectedDistributor}
        handleOnChange={handleDistributorChange}
        itemToString={(item) => item}
        className="absolute right-44 top-0 mt-6 w-36 mr-8"
      />
      <GenericFilter
        items={dayCounts}
        selectedItem={selectedDay}
        handleOnChange={handleDayCountChange}
        itemToString={dayCountToString}
        className="absolute right-8 top-0 mt-6 w-40 max-w-sm"
      />
      <SummaryCards selectedDay={selectedDay} selectedDistributor={selectedDistributor} />
      <div className="mx-auto">
        <DataTable loading={loading} columns={columns} data={data} />
      </div>
    </div>
  )
}