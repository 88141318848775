export function Logo() {
  return <div class="flex flex-shrink-0 items-center mx-auto">
    <img
      class="block h-8 w-8 mr-2"
      src="/img/promoted.png"
      alt="Promoted"
    />
    <span className="font-semibold text-lg text-plue-900">Promoted</span>
  </div>
}
