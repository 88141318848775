import { BarList } from "@tremor/react"
import { useState } from "preact/compat"
import { api_fetch } from "../../api/client.tsx"
import { useAsyncEffect } from "../../utils/util.tsx"
import { currencyFormatter, QueryData } from "../common.tsx"
import { LoadingSpinner } from "./summary_cards.tsx"
import { CATEGORY_COLORS } from "./table/columns.tsx"

type DeductionCategoryData = {
  name: string
  value: number
}

export function CategoryBarChart({ selectedDay, selectedDistributor }: { selectedDay: number, selectedDistributor: string }) {
  const [data, setData] = useState<DeductionCategoryData[]>([])
  const [loading, setLoading] = useState(true)

  useAsyncEffect(async () => {
    let qs = new URLSearchParams()
    qs.set("days", selectedDay.toString())
    qs.set("distributor", selectedDistributor === "All Distributors" ? "" : selectedDistributor)
    qs.set("query_name", "category")
    let res = await api_fetch<QueryData>(`/deductions/query?${qs}`)
    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }
    setLoading(false)

    const headerIndex = res.value.data.headers.reduce((acc, header, index) => {
      acc[header] = index
      return acc
    }, {} as { [key: string]: number })

    res.value.data.rows.sort((a, b) => a[headerIndex.total_invoice_amount] - b[headerIndex.total_invoice_amount])

    setData(
      res.value.data.rows.map((row, idx) => ({
        name: row[headerIndex.category_name] as string,
        value: Math.abs(row[headerIndex.total_invoice_amount]) as number,
        href: `?search=${row[headerIndex.category_name] as string}`,
        target: "",
        color: CATEGORY_COLORS[row[headerIndex.category_name]],
      }))
    )
  }, [selectedDay, selectedDistributor])

  if (loading || !data) {
    return <LoadingSpinner color="blue" />
  }

  return (
      <div class="">
        <BarList data={data} className="max-w-2xl" valueFormatter={currencyFormatter} />
      </div>
  )
}
