import React, { useState } from "preact/compat"
import { Alert } from "../type"

export type ErrorMessages = null | string[]

/**
 * State of a specific field in a form
 */
export type FieldState<T> = {
  value: T
  set_value: (e: React.ChangeEvent<HTMLInputElement>) => void
  errors: ErrorMessages
  set_errors: (e: ErrorMessages) => void
}

export type FormFieldState<T> = {
  [P in keyof T]: FieldState<T[P]>
}

/**
 * State of the form as a whole
 */
interface FormLevelState<T> {
  form_alerts: Alert[] | null
  set_form_errors: (e: string | ErrorMessages) => void
  set_form_success: (message: string) => void
  set_form_data: (t: Partial<T>) => void
  form_data: T
}

export type FormState<T> = FormFieldState<T> & FormLevelState<T>

export function useFormState<T extends object>(initial: T): FormState<T> {
  let [form_data, set_form_data] = useState<T>(initial)
  let [form_alerts, set_form_alerts] = useState<Alert[] | null>(null)
  let field_error_map = Object.fromEntries(Object.entries(initial).map(([k, _v]) => [k, null])) as FieldErrorMap<T>
  let [field_errors, set_field_errors] = useState(field_error_map)

  let form_field_state = Object.fromEntries(
    Object.entries(initial).map(([k, _v]) => {
      return [k, {
        // @ts-ignore
        value: form_data[k],
        set_value: (e: React.ChangeEvent<HTMLInputElement>) =>
          set_form_data(form_data => {
            // @ts-ignore
            form_data[k] = e.target!.value
            return { ...form_data }
          }),
        // @ts-ignore
        errors: field_errors[k],
        set_errors: (e: ErrorMessages) =>
          set_field_errors(field_errors => {
            // @ts-ignore
            field_errors[k] = e
            return { ...field_errors }
          }),
      } as FieldState<T[keyof T]>]
    }),
  )

  let set_form_errors = (err: string | ErrorMessages) =>
    set_form_alerts(_a => {
      if (err === null) return null
      if (typeof err === "string") return [{ type: "danger", message: err }]
      return err.map(e => ({ type: "danger", message: e }))
    })

  let set_form_success = (message: string) =>
    set_form_alerts(_a => {
      return [{ type: "success", message }]
    })

  return {
    ...form_field_state,
    form_alerts,
    set_form_errors,
    set_form_success,
    set_form_data,
    form_data,
  } as FormState<T>
}

type FieldErrorMap<T> = {
  [P in keyof T]: ErrorMessages
}
