import { Card } from "@/components/ui/card.tsx"
import { Interlude } from "./util"

export function Team() {
  const team = [
    {
      name: "Kurt Wolf",
      title: "CEO",
      profile_picture: "/img/kurt.png",
      linkedin: "https://www.linkedin.com/in/kurtbuilds",
      logos: [],
      background:
        "Kurt has spent his entire career in tech, and brings over 24 years of engineering experience in addition to a previous role as COO of a CPG-related company, and cofounder of now Series-B startup that helped consumers settle their debt, similar to how Promoted now helps brands win their money back. ",
    },
    {
      name: "Nat Noone",
      title: "CRO",
      profile_picture: "/img/nat.png",
      linkedin: "https://www.linkedin.com/in/nat-noone-3211997/",
      logos: [],
      background:
        "Nat brings over 30 years of CPG experience. He grew up on a truck with a juice company that was bought by Odwalla (later bought by Coca-Cola where he held several leadership positions). Most recently, he founded a national refrigierated DSD company and Wave Soda. ",
    },

    {
      name: "Joey Caffrey",
      title: "CTO",
      profile_picture: "/img/joey.jpeg",
      linkedin: "https://www.linkedin.com/in/joey-caffrey-58155687/",
      logos: [],
      background:
        "Software engineer by trade but CPG kid by birth. His mom was at Nieslen for 25+ years while his dad worked at Advantage, and he loves to see new products succeed so building Promoted feels like a homecoming. ",
    },
  ]
  return (
    <div class="pb-24">
      <Interlude
        badgeCopy="Company"
        title="Our team"
        description="We have a mix of CPG and tech experience, but brands choose us because we obsess over helping them get their money back."
      />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-4 max-w-7xl mx-auto">
        {team.map((member, index) => (
          <Card key={index} className="flex flex-col p-4 bg-white shadow-lg rounded-lg">
            <div className="flex items-center justify-between w-full mb-4">
              <div className="flex items-center">
                <div className="w-16 h-16 rounded-full overflow-hidden">
                  <img
                    src={member.profile_picture}
                    alt={member.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="ml-4">
                  <p className="text-gray-900 font-bold text-lg">{member.name}</p>
                  <p className="text-gray-700">{member.title}</p>
                </div>
              </div>
              <a href={member.linkedin} className="ml-4" target="_blank" rel="noopener noreferrer">
                <img src="/img/linkedin.svg" alt="LinkedIn" className="w-6 h-6" />
              </a>
            </div>
            <p className="text-plue-900 mt-2 text-center">{member.background}</p>
          </Card>
        ))}
      </div>
    </div>
  )
}
